import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import ImgCustom from "../../components/imgCustom/imgCustom"
import links from "../../utils/links"
import BlockConseilsList from "../../components/blockConseilsList/blockConseilsList"
import Layout from "../../components/layout/layout"
import Pagination from "../../components/pagination/pagination"
import Seo from "../../components/seo/seo"
import "../conseilsAll/conseilsAll.scss"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"

const temp = elem => {
  const [visible, setVisible] = useState(true)

  const delay = 1
  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, delay)
  }, [delay])

  return visible ? <div>{elem}</div> : <div />
}

const conseilsCategories = ({ data, pageContext }) => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const dataFormated = () => {
    let result = []
    if (data) {
      if (data.hasOwnProperty("allNodePostConseils")) {
        const { edges } = data.allNodePostConseils
        if (edges) {
          edges.forEach(element => {
            const { node } = element
            if (node) {
              if (node?.relationships.taxonomy_term__post_categorie !== null) {
                const id =
                  node.relationships.taxonomy_term__post_categorie[0].id
                const name_taxo =
                  node.relationships.taxonomy_term__post_categorie[0].name
                const slug_taxo =
                  node.relationships.taxonomy_term__post_categorie[0].field_slug
                    .value
                const description = node.field_description_article_short.value
                // const title = node.field_titre_article_short
                const title = node.title
                const src = node.relationships.field_cover_short.localFile
                const alt = node.field_cover_short.alt
                const slug = node.field_slug

                if (id && name_taxo) {
                  const indexByIdTaxo = result.findIndex(el => el.id === id)
                  const createRow = {
                    category: name_taxo,
                    slug_category: slug_taxo,
                    title: title,
                    description: description,
                    src: src,
                    alt: alt,
                    slug: slug,
                  }
                  if (indexByIdTaxo === -1) {
                    result.push({
                      id: id,
                      category: name_taxo,
                      slug_category: slug_taxo,
                      data: [createRow],
                    })
                  }
                  if (indexByIdTaxo !== -1) {
                    result[indexByIdTaxo].data.push(createRow)
                  }
                }
              }
            }
          })
        }
        // if (result.length) {
        //     result.forEach(elm => {
        //         elm.data.sort((a, b) => (a.order > b.order ? 1 : -1))
        //     })
        // }
      }
    }
    return result
  }

  const dataList = dataFormated()

  const categoryList = [...data.allTaxonomyTermPostCategorie.nodes].sort(
    (a, b) => (a.weight > b.weight ? 1 : -1)
  )

  const formatListDesktop = () => {
    return (
      categoryList.map((el, idx) => (
        <a
          key={idx}
          href={el.field_slug.value === "alarme-maison"
          ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
          : (`/conseils/${el.field_slug.value}`) + (isTherecampagne ? "/" + campagne : "")}
          className={"ls-n mb-5 item is-active m-2"}
        >
          <button
            className={
              el.name === pageContext.category
                ? "btn-hover-underline ta-l c-1"
                : "btn-hover-underline ta-l"
            }
          >
            {el.name}
          </button>
        </a>
      ))
    ) 
  }

  const formatListResponsive = () => {
    return (
      categoryList.map((el, index) => (
        <li
          key={index}
          onClick={() =>
            el.field_slug.value === "alarme-maison"
              ? (window.location.href = `/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
              : (window.location.href = `/conseils/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
          }
          className={
            selected === index
              ? "ls-n mb-5 item is-active"
              : "ls-n mb-5 item"
          }
        >
          <a
            key={index}
            href={el.field_slug.value === "alarme-maison"
            ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
            : (`/conseils/${el.field_slug.value}`) + (isTherecampagne ? "/" + campagne : "")}
          >
            <button className="btn-hover-underline ta-l ">
              {el.name}
            </button>
          </a>
        </li>
      ))
    )
  }

  const seoPage = {
    title: pageContext.seoTitle,
    description: data.nodePageConseils.field_seo_description,
  }

  if (pageContext.currentPage > 1) {
    seoPage.title = seoPage.title + " | Page " + pageContext.currentPage
  }

  const topPost = data.nodePageConseils.relationships.field_top_post

  let pagination = {
    currentPage: pageContext.currentPage,
    next: 0,
    prev: 0,
    tabs: [pageContext.numPagesCategory],
  }

  const breadcrumb = [
    {
      name: "Accueil",
      url: links.getLink("home") + campagne,
    },
    {
      name: "Conseils",
      url: links.getLink("conseils") + campagne,
    },
    {
      name: pageContext.category,
      url: `/conseils/${pageContext.slug}` + (isTherecampagne ? "/" + campagne : ""),
    },
  ]

  const isNext = () => {
    if (pageContext.currentPage < pageContext.numPagesCategory) {
      pagination = {
        ...pagination,
        next: pageContext.currentPage + 1,
      }
    } else {
      pagination = {
        ...pagination,
        next: 0,
      }
    }
  }

  const isPrev = () => {
    if (pageContext.currentPage > 0) {
      pagination = {
        ...pagination,
        prev: pageContext.currentPage - 1,
      }
    } else {
      pagination = {
        ...pagination,
        prev: 0,
      }
    }
  }

  const fillTabs = () => {
    for (let i = 0; i < pageContext.numPagesCategory; i++) {
      pagination.tabs[i] = i + 1
    }
  }

  isNext()
  isPrev()
  fillTabs()

  function formatUrl (page, slug) {
    if (page) {
      if (slug == "") {
        if (page === 1) {
          return links.getLink("conseils") + campagne
        } else {
          return `/conseils-page-${page}` + (isTherecampagne ? "/" + campagne : "")
        }
      }
      else {
        if (page === 1) {
          return `/conseils/${slug}` + (isTherecampagne ? "/" + campagne : "")
        } else {
          return `/conseils/${slug}-page-${page}` + (isTherecampagne ? "/" + campagne : "")
        }
      }
    }
  }

  const formatPrePagination = (data, slug) => {
    if (data.prev != 0){
      return (
          <li className="li">
              <a rel="prev" href = {formatUrl(data.prev,slug)} >
              <button
                className="d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next"
                style={{
                  height: "15px",
                  width: "25px",
                  transform: "rotate(180deg)",
                }}
              ></button></a>
          </li>
      )
    }
    else {
      return ''
    }
  }

  const formatPostPagination = (data, slug) => {
    if (data.next != 0){
      return (
          <li className="li">
            <a rel="next" href = {formatUrl(data.next,slug)} >
            <button
              className="d-f px-0  ml-1 ai-c jc-c icon-arrow-pagination-next"
              style={{ height: "15px", width: "25px" }}
            ></button></a>
          </li>
      )
    }
    else {
      return ''
    }
  }

  const formatMidPagination = (data, slug) => {
      return (
        data.tabs.map((el, index) => (
          <li className="ls-n 2" key={index}>
            <a href = {formatUrl(el,slug)} >
            <button
              className={
                data.currentPage === el
                  ? "page-item  ff-gb is-active"
                  : "page-item ff-gb "
              }
            >
              <span>{el}</span>
            </button></a>
          </li>
        ))
      )
  }

  let i = 0

  const useWindoSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    })
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    }, [])
    return windowSize
  }
  let currentCategoryIdx = 0
  categoryList.forEach((category, idx) => {
    if (category.name === pageContext.category) currentCategoryIdx = idx
  })

  const [openNav, setOpenNav] = useState(false)
  const [selected, setSelected] = useState(currentCategoryIdx)
  const size = useWindoSize()

  const selectCategory = index => {
    setSelected(index)
    if (index === null) {
      window.location.href = links.getLink("conseils") + campagne
    }
  }

    return (
      <Layout isViewPromoOnHeader={true}>
        <Seo
          title={seoPage.title}
          description={seoPage.description + (pageContext.currentPage > 1 ? " Page " + pageContext.currentPage + "." : "")}
          linkCanonical={
            pageContext.currentPage > 1
              ? `conseils/${pageContext.slug}-page-${pageContext.currentPage}`
              : `conseils/${pageContext.slug}`
          }
          page={pagination}
          slug={pageContext.slug}
        />
        <div className="block-page-conseil">
          <div className="bc-2 d-f fd-c ">
            <Breadcrumb data={breadcrumb} addStyle={" p-1"} />
            {/* <h1 className={"title-on-hero d-f jc-c ai-c pb-6 px-md-3 px-md-3"}>{bannerTitle}</h1> */}
            <div
              className={
                "title-on-hero d-f jc-c ai-c pb-6 px-md-3 px-md-3"
                // breadcrumb.length > 0
                //   ? "title-on-hero d-f jc-c ai-c pb-6 px-md-3 px-md-3"
                //   : "title-on-hero d-f jc-c ai-c py-6 px-md-3 px-md-3"
              }
              dangerouslySetInnerHTML={{
                __html: pageContext.bannerTitle,
              }}
            ></div>
          </div>
          <div className="wrapper container-page-conseil d-f ai-c jc-c fd-c fd-md-c">
            {/*** TOP ARTICLE */}
            <BlockConseilsList data={topPost} top={true} />
  
            {/** CATEGORIES */}
            {size.width > 768 ? (
              <>
                <div className="separator icon mr-2 ml-2 "></div>
                <div className="d-f ai-c jc-c fd-r p-0">
                  <span
                    onClick={() =>
                      (window.location.href = links.getLink("conseils") + campagne)
                    }
                    className={"ls-n mb-5 item is-active m-2"}
                  >
                    <button className="btn-hover-underline ta-l">
                      Tous les articles
                    </button>
                  </span>
                  {/* {(typeof window !== `undefined`) && formatListDesktop()} */}
                  {categoryList.map((el, idx) => (
                    <a
                      key={idx}
                      href={el.field_slug.value === "alarme-maison"
                      ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                      : (`/conseils/${el.field_slug.value}`) + (isTherecampagne ? "/" + campagne : "")}
                      className={"ls-n mb-5 item is-active m-2"}
                    >
                      <button
                        className={
                          el.name === pageContext.category
                            ? "btn-hover-underline ta-l c-1"
                            : "btn-hover-underline ta-l"
                        }
                      >
                        {el.name}
                      </button>
                    </a>
                  ))}
                </div>
              </>
            ) : (
              <div className="category-list">
                <label className="label-current-select br-12 d-n d-md-f p-r bcg-2">
                  <input
                    type="text"
                    readOnly
                    className="input-hide-toggle"
                    onClick={() => setOpenNav(openNav ? false : true)}
                    onBlur={() =>
                      setTimeout(() => {
                        setOpenNav(false)
                      }, 200)
                    }
                  />
                  <p className="d-f ai-c jc-sb mb-0 w-100 ff-gbook">
                    {selected === null
                      ? "Toutes les articles"
                      : categoryList[selected].name}
                    <span className="icon-arrow-down-c1 d-b"></span>
                  </p>
                </label>
                <ul
                  className={
                    openNav
                      ? "ul d-f fd-c is-active nav-category"
                      : "nav-category ul d-f fd-c"
                  }
                >
                  <li
                    onClick={() => selectCategory(null)}
                    className={
                      selected === null
                        ? "ls-n item mb-5 is-active"
                        : "ls-n item mb-5"
                    }
                  >
                    <a href={links.getLink("conseils") + campagne}>
                      <button className="btn-hover-underline ta-l">
                        Toutes les articles
                      </button>
                    </a>
                  </li>
                  {/* {typeof window !== `undefined` && formatListResponsive()} */}
                  {categoryList.map((el, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          el.field_slug.value === "alarme-maison"
                            ? (window.location.href = `/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                            : (window.location.href = `/conseils/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                        }
                        className={
                          selected === index
                            ? "ls-n mb-5 item is-active"
                            : "ls-n mb-5 item"
                        }
                      >
                        <a
                          key={index}
                          href={el.field_slug.value === "alarme-maison"
                          ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                          : (`/conseils/${el.field_slug.value}`) + (isTherecampagne ? "/" + campagne : "")}
                        >
                          <button className="btn-hover-underline ta-l ">
                            {el.name}
                          </button>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            )}
  
            {/** LIST ARTCILES */}
            <div className="d-f ai-fs jc-c fd-r f-w">
              {dataList.length ? (
                dataList.map(el =>
                  i !== dataList.length - 1 ? (
                    <>
                      <BlockConseilsList data={el.data} top={false} />
                      {/* <p style={{ display: "none" }}>{i++}</p> */}
                      <>{temp(i++)}</>
                    </>
                  ) : (
                    <BlockConseilsList data={el.data} top={false} limit={true} />
                  )
                )
              ) : (
                <p>Aucun articles pour cette catégorie</p>
              )}
            </div>
            {/** PAGINATION */}
            <div className="py-3">
              {pagination && (
                <Pagination
                  data={pagination}
                  slug={pageContext.slug}
                  addClass={"jc-fe"}
                  conseil={true}
                  prePagination={formatPrePagination(pagination, pageContext.slug)}
                  midPagination={formatMidPagination(pagination, pageContext.slug)}
                  postPagination={formatPostPagination(pagination, pageContext.slug)} 
                  />
              )}
            </div>
          </div>
        </div>
      </Layout>
    )

}

export const query = graphql`
  query ($category: String, $skip: Int, $limit: Int) {
    allNodePostConseils(
      limit: $limit
      skip: $skip
      sort: { fields: changed, order: DESC }
      filter: {
        relationships: {
          taxonomy_term__post_categorie: {
            elemMatch: { name: { eq: $category } }
          }
        }
      }
    ) {
      edges {
        node {
          title
          field_slug
          field_titre_article_short
          relationships {
            taxonomy_term__post_categorie {
              name
              id
              field_slug {
                value
              }
            }
            field_cover_short {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
          }
          field_description_article_short {
            value
          }
          field_cover_short {
            alt
          }
        }
      }
    }
    nodePageConseils {
      title
      field_seo_titre
      field_seo_description
      field_banner_titre {
        value
      }
      relationships {
        field_top_post {
          title
          field_titre_article_short
          field_slug
          relationships {
            field_cover_short {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
            taxonomy_term__post_categorie {
              name
              field_slug {
                value
              }
            }
          }
          field_cover_short {
            alt
          }
          field_description_article_short {
            value
          }
        }
      }
    }
    allTaxonomyTermPostCategorie {
      nodes {
        name
        field_slug {
          value
        }
        weight
      }
    }
  }
`

export default conseilsCategories
